// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-page-js": () => import("/Users/deepakkarki/Desktop/discover-dev/gatsby-site/src/templates/page.js" /* webpackChunkName: "component---src-templates-page-js" */),
  "component---src-templates-blog-post-js": () => import("/Users/deepakkarki/Desktop/discover-dev/gatsby-site/src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-templates-archive-page-archive-page-js": () => import("/Users/deepakkarki/Desktop/discover-dev/gatsby-site/src/templates/archive-page/archive-page.js" /* webpackChunkName: "component---src-templates-archive-page-archive-page-js" */),
  "component---src-templates-tag-page-tag-page-js": () => import("/Users/deepakkarki/Desktop/discover-dev/gatsby-site/src/templates/tag-page/tag-page.js" /* webpackChunkName: "component---src-templates-tag-page-tag-page-js" */),
  "component---src-templates-blog-posts-index-js": () => import("/Users/deepakkarki/Desktop/discover-dev/gatsby-site/src/templates/blog-posts-index.js" /* webpackChunkName: "component---src-templates-blog-posts-index-js" */),
  "component---src-templates-category-posts-index-js": () => import("/Users/deepakkarki/Desktop/discover-dev/gatsby-site/src/templates/category-posts-index.js" /* webpackChunkName: "component---src-templates-category-posts-index-js" */),
  "component---src-templates-series-posts-index-js": () => import("/Users/deepakkarki/Desktop/discover-dev/gatsby-site/src/templates/series-posts-index.js" /* webpackChunkName: "component---src-templates-series-posts-index-js" */),
  "component---src-pages-404-js": () => import("/Users/deepakkarki/Desktop/discover-dev/gatsby-site/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("/Users/deepakkarki/Desktop/discover-dev/gatsby-site/src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-archive-js": () => import("/Users/deepakkarki/Desktop/discover-dev/gatsby-site/src/pages/archive.js" /* webpackChunkName: "component---src-pages-archive-js" */),
  "component---src-pages-blog-categories-index-js": () => import("/Users/deepakkarki/Desktop/discover-dev/gatsby-site/src/pages/blog/categories/index.js" /* webpackChunkName: "component---src-pages-blog-categories-index-js" */),
  "component---src-pages-blog-index-js": () => import("/Users/deepakkarki/Desktop/discover-dev/gatsby-site/src/pages/blog/index.js" /* webpackChunkName: "component---src-pages-blog-index-js" */),
  "component---src-pages-blog-series-index-js": () => import("/Users/deepakkarki/Desktop/discover-dev/gatsby-site/src/pages/blog/series/index.js" /* webpackChunkName: "component---src-pages-blog-series-index-js" */),
  "component---src-pages-index-js": () => import("/Users/deepakkarki/Desktop/discover-dev/gatsby-site/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-subscribe-js": () => import("/Users/deepakkarki/Desktop/discover-dev/gatsby-site/src/pages/subscribe.js" /* webpackChunkName: "component---src-pages-subscribe-js" */),
  "component---src-pages-tags-js": () => import("/Users/deepakkarki/Desktop/discover-dev/gatsby-site/src/pages/tags.js" /* webpackChunkName: "component---src-pages-tags-js" */)
}

exports.data = () => import("/Users/deepakkarki/Desktop/discover-dev/gatsby-site/.cache/data.json")

